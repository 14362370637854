<template>
  <div>
    <h1>Классификатор отходов</h1>
    <search-block>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-select
            clearable
            :disabled="ss01Dictionary.content.length <= 1"
            @change="searchWasteByCode"
            class="searchPole"
            v-model="search.code"
            filterable
            remote
            value-key="id"
            reserve-keyword
            placeholder="Код отхода"
            :remote-method="searchCode"
            :loading="search.loadingCodes"
          >
            <el-option
              v-for="item in ss01CodeList"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            clearable
            :disabled="ss01Dictionary.content.length <= 1"
            @change="searchWasteByName"
            class="searchPole"
            v-model="search.name"
            filterable
            remote
            value-key="id"
            reserve-keyword
            placeholder="Наименование"
            :remote-method="searchWasteName"
            :loading="search.loadingNames"
          >
            <el-option
              v-for="item in wasteNames"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select
            clearable
            :disabled="ss01Dictionary.content.length <= 1"
            @change="searchWasteByDangerDegree"
            class="searchPole"
            v-model="search.dangerDegree"
            filterable
            remote
            value-key="id"
            reserve-keyword
            placeholder="Степень опасности"
          >
            <el-option
              v-for="item in dangerDegree.content"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-button @click="clearSearch" type="success">Сброс</el-button>
          <el-button @click="createClassifier" type="primary"
            >Добавить классификатор</el-button
          >
        </el-col>
      </el-row>
    </search-block>

    <el-table stripe border :data="ss01Dictionary.content" style="width: 100%">
      <el-table-column prop="wasteCode" label="Код отхода"> </el-table-column>
      <el-table-column prop="wasteName" label="Наименование"> </el-table-column>
      <el-table-column
        prop="dangerDegreeClassifierDTO.name"
        label="Степень опасности и класс опасности"
      >
      </el-table-column>
      <el-table-column
        prop="bazelConvinceWasteCode"
        label="Код вида отхода по классификации"
      >
        <template slot-scope="scope">
          <ul v-if="scope.row.bazelConvinceWasteCode[0] !== null">
            <li
              :key="key"
              v-for="(item, key) in scope.row.bazelConvinceWasteCode"
            >
              {{ item }}
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column
        prop="wasteCodeByDecision2000"
        label=" Код отхода по Решению Совета 2000/532/EC"
      >
        <template slot-scope="scope">
          <ul v-if="scope.row.wasteCodeByDecision2000[0] !== null">
            <li
              :key="key"
              v-for="(item, key) in scope.row.wasteCodeByDecision2000"
            >
              {{ item }}
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column
        prop="dangerWasteDisposalPSS06DTO.name"
        label="Сделка об отчуждении опасных отходов"
      >
      </el-table-column>
      <el-table-column width="250" label="Действия">
        <template slot-scope="scope">
          <el-button
            type="warning"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            >Редактировать</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >Удалить</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="ss01Dictionary.size"
      :current-page.sync="ss01Dictionary.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="ss01Dictionary.totalElements"
    >
    </el-pagination>

    <custom-modal
      :title="'Классификатор отходов'"
      :visible="showModal === 'createClassifier'"
    >
      <classifiers-create-form></classifiers-create-form>
    </custom-modal>

    <custom-modal
      :title="'Классификатор отходов'"
      :visible="showModal === 'updateClassifier'"
    >
      <classifiers-update-form
        :classifier="updatedClassifier"
      ></classifiers-update-form>
    </custom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchBlock from "@/components/searchBlock";
import notification from "@/mixins/notification";
import ClassifiersCreateForm from "@/components/admin/classifiers/classifiersCreateForm";
import ClassifiersUpdateForm from "@/components/admin/classifiers/classifiersUpdateForm";
import CustomModal from "@/components/customModal";

export default {
  mixins: [notification],
  name: "classifiersEdit",
  components: {
    CustomModal,
    ClassifiersUpdateForm,
    ClassifiersCreateForm,
    SearchBlock,
  },
  data() {
    return {
      updatedClassifier: {},
      createModalShow: false,
      updateModalShow: false,
      search: {
        code: "",
        loadingCodes: false,
        name: "",
        loadingNames: false,
        dangerDegree: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      ss01Dictionary: "GETSS01DICTIONARY",
      ss01CodeList: "GETSSS01CODELIST",
      wasteNames: "GETWASTENAMES",
      dangerDegree: "GETSUBCLASSIFIERDATA",
      showModal: "GETSHOWMODAL",
    }),

    getPaginationOptions: {
      get() {
        return {
          page: this.ss01Dictionary.number,
          size: this.ss01Dictionary.size,
        };
      },
    },
  },

  methods: {
    createClassifier() {
      this.$store.dispatch("setShowModal", "createClassifier");
    },

    clearSearch() {
      this.getSs01dictionary({ page: 0, size: 20 });
      this.search = {
        code: "",
        loadingCodes: false,
        name: "",
        loadingNames: false,
        dangerDegree: "",
      };
    },

    handleEdit(id, item) {
      console.log(id);
      console.log(item);
      this.updatedClassifier = item;
      this.$store.dispatch("setShowModal", "updateClassifier");
    },

    handleDelete(id, item) {
      console.log(id);
      console.log(item);
      this.$store
        .dispatch("deleteSs01", item.ss01DictionaryId)
        .then((response) => {
          if (response.data === "Deleted") {
            this.notification("Удалено", "Классификатор удален", "success");
            this.getSs01dictionary(this.getPaginationOptions);
            this.clearSearch();
          }
        });
    },

    handleSizeChange(val) {
      this.getSs01dictionary({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getSs01dictionary({ page: val - 1, size: this.ss01Dictionary.size });
    },

    searchCode(query) {
      this.getCodeList(query);
    },

    searchWasteName(query) {
      this.getWasteNames(query);
    },

    searchWasteByCode() {
      this.$store.dispatch(
        "findSS01DictionaryValueByClassifierId",
        this.search.code.id
      );
      this.search.dangerDegree =
        this.ss01Dictionary.content[0].dangerDegreeClassifierDTO;
    },

    searchWasteByName() {
      this.$store.dispatch(
        "findSS01DictionaryValueByClassifierId",
        this.search.name.id
      );
      this.search.dangerDegree =
        this.ss01Dictionary.content[0].dangerDegreeClassifierDTO;
    },

    searchWasteByDangerDegree() {
      console.log(this.search.dangerDegree);
      let data = {
        dangerDegreeId: this.search.dangerDegree.id,
        page: this.getPaginationOptions.page,
        size: this.getPaginationOptions.size,
      };

      console.log(data);
      this.$store.dispatch("findSS01DictionaryValueByDangerDegreeId", data);
    },

    async getSs01dictionary(data) {
      await this.$store.dispatch("getSs01Dictionary", data);
    },

    async getCodeList(query) {
      this.search.loadingCodes = true;
      await this.$store.dispatch("getCodeList", query);
      this.search.loadingCodes = false;
    },

    async getWasteNames(query) {
      this.search.loadingNames = true;
      await this.$store.dispatch("getWasteNames", query);
      this.search.loadingNames = false;
    },

    getSubClassifierById() {
      let data = {
        id: 1,
        size: 20,
        page: 0,
      };
      this.$store.dispatch("getAllDataSubClassifier", data);
    },
  },

  async mounted() {
    await this.getSs01dictionary({ page: 0, size: 20 });
    await this.getSubClassifierById();
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <el-form
      label-position="top"
      :model="classifier"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
    >
      <el-form-item label="Код отхода" prop="wasteCode">
        <el-input v-model="classifier.wasteCode"></el-input>
      </el-form-item>

      <el-form-item label="Наименование" prop="wasteName">
        <el-input v-model="classifier.wasteName"></el-input>
      </el-form-item>

      <el-form-item
        label="Код вида отхода по классификации"
        prop=""
      >
        <el-input v-model="classifier.bazelConvinceWasteCode"></el-input>
      </el-form-item>

      <el-form-item
        label="Степень опасности и класс опасности"
        prop="dangerDegreeClassifierDTO"
      >
        <el-select
          clearable
          class="searchPole"
          v-model="classifier.dangerDegreeClassifierDTO"
          filterable
          remote
          value-key="id"
          reserve-keyword
          placeholder="Степень опасности"
        >
          <el-option
            v-for="item in dangerDegree.content"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="Сделка об отчуждении опасных отходов"
        prop="dangerWasteDisposalPSS06DTO"
      >
        <el-switch
          v-model="classifier.dangerWasteDisposalPSS06DTO"
          active-text="Да"
          :active-value="1"
          :inactive-value="2"
          inactive-text="Нет"
        >
        </el-switch>
      </el-form-item>

      <el-form-item
        label="Код отхода по Решению Совета 2000/532/EC"
        prop=""
      >
        <el-input v-model="classifier.wasteCodeByDecision2000"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="success" @click="submitForm('ruleForm')"
          >Добавить</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "classifiersCreateForm",
  data() {
    return {
      classifier: {
        bazelConvinceWasteCode: [],
        dangerDegreeClassifierDTO: null,
        dangerWasteDisposalPSS06DTO: 2,
        ss01DictionaryId: 0,
        wasteCode: 0,
        wasteCodeByDecision2000: [],
        wasteName: "",
      },

      rules: {
        bazelConvinceWasteCode: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        dangerDegreeClassifierDTO: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        dangerWasteDisposalPSS06DTO: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        wasteCode: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        wasteCodeByDecision2000: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        wasteName: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      dangerDegree: "GETSUBCLASSIFIERDATA",
    }),
  },

  methods: {
    async getSs01dictionary(data) {
      await this.$store.dispatch("getSs01Dictionary", data);
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let classifier = {
            bazelConvinceWasteCode:
              this.classifier.bazelConvinceWasteCode.split(", "),
            dangerDegreeClassifierDTO:
              this.classifier.dangerDegreeClassifierDTO,
            dangerWasteDisposalPSS06DTO: {
              id: this.classifier.dangerWasteDisposalPSS06DTO,
              name: null,
            },
            ss01DictionaryId: this.classifier.ss01DictionaryId,
            wasteCode: this.classifier.wasteCode,
            wasteCodeByDecision2000:
              this.classifier.wasteCodeByDecision2000.split(", "),
            wasteName: this.classifier.wasteName,
          };

          console.log(classifier);
          this.$store.dispatch("createWasteClassifier", classifier).then(() => {
            this.notification("Успешно", "Создан классификатор", "success");
            this.getSs01dictionary({ page: 0, size: 20 });
            this.$store.dispatch("setShowModal", null);
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <classifiers-edit></classifiers-edit>
  </div>
</template>

<script>
import ClassifiersEdit from "@/components/admin/classifiers/classifiersEdit";
export default {
  name: "classifiersEditView",
  components: { ClassifiersEdit },
};
</script>

<style scoped></style>
